import React, { useMemo } from 'react';
import { Dict, PageRegistration, useGlobalState, useGlobalStateContext } from 'piral-core';
import { useLocation } from 'react-router';

import './version-info.css';

function getPiletName(pathname: string, pages: Dict<PageRegistration>) {
  const firstSegment = '/' + pathname.split('/')[1];
  if (firstSegment == '/') {
    return null;
  }

  for (const [key, value] of Object.entries(pages)) {
    if (key.startsWith(firstSegment)) {
      return value.pilet;
    }
  }
  return '';
}

export const VersionInfo = () => {
  const pages = useGlobalState(s => s.registry.pages);
  const location = useLocation();

  const piletName = useMemo(() => {
    return getPiletName(location.pathname, pages);
  }, [location.pathname, pages]);

  const state = useGlobalStateContext();
  const version = state.apis[piletName]?.meta.version;

  return version && <p className="neo-version-info">v{version}</p>;
};
