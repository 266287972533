import React from 'react';
import { useTranslate } from 'piral-translate';
import { useKeycloak } from '../../keycloak-api';
import { useLocation } from 'react-router';
import { LoginButton } from '../LoginButton/LoginButton';

export const LoginNotification = () => {
  const { keycloak } = useKeycloak();
  const translate = useTranslate();
  const authenticated = keycloak.authenticated;
  const location = useLocation();

  if (!authenticated && !location.pathname.includes('salamander-info') && location.pathname === '/') {
    return (
      <div className="neo-info-card">
        <p>{translate('bitte-melden-sie-sich-an')}</p>
        <p>
          <LoginButton keycloak={keycloak} />
        </p>
      </div>
    );
  }
};
