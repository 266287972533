import { SnowfallProps } from 'react-snowfall';
import { PiralPlugin } from 'piral-core';
import { snowfallStorageKey } from './constants';
import { SnowfallApi } from './types';
import * as actions from './actions';
import { isChristmasTime } from './components/SnowFallButton/SnowfallButton';

export const defaultSettings: SnowfallProps = {
  snowflakeCount: 250,
  color: '#dee4fd',
  radius: [0.5, 3.0],
  speed: [1.0, 3.0],
  wind: [-0.5, 2.0],
  changeFrequency: 200,
};

/**
 * Creates new Pilet API extensions for enabling snowfall.
 */
export function createSnowfallApi(settings?: SnowfallProps): PiralPlugin<SnowfallApi> {
  const stored = localStorage.getItem(snowfallStorageKey);
  if (stored) {
    settings = JSON.parse(stored);
  } else {
    settings = settings || defaultSettings;
  }

  if (!isChristmasTime()) {
    settings = null;
    localStorage.removeItem(snowfallStorageKey);
  }

  return context => {
    context.defineActions(actions);

    context.dispatch(state => ({
      ...state,
      snowSettings: settings,
    }));

    return {
      getSnowSettings() {
        return context.readState(state => state.snowSettings);
      },
    };
  };
}
