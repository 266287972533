import React from 'react';
import { Link } from 'react-router-dom';

import './sip-tile.css';

export const SipTile = ({ linkTo, title, description, imgSource }) => {
  return (
    <Link to={linkTo} className="neo-sip-tile">
      <div className="neo-sip-tile-container">
        <img src={imgSource} alt="icon" />
        <div className="neo-tile-info">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </Link>
  );
};
