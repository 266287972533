import React, { useState, useEffect, useRef } from 'react';
import { MenuRootItem, SipMenuItem } from '../../siplet-api';
import { PiletOnlyMenu } from '../PiletOnlyMenu/PiletOnlyMenu';
import { VersionInfo } from '../VersionInfo/VersionInfo';
import { ExtensionSlot } from 'piral-core';
import { useTranslate } from 'piral-translate';
import { useKeycloak } from '../../keycloak-api';
import { Breadcrumbs } from 'piral-breadcrumbs';

import './sidebar.css';

export const Sidebar = () => {
  const translate = useTranslate();
  const { authenticated } = useKeycloak();
  const dashboardItem: MenuRootItem = {
    route: '/',
    icon: require('../../assets/svgs/home.svg'),
    title: translate('apps'),
  };

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [manuallyToggled, setManuallyToggled] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = e => {
    e.stopPropagation();
    setManuallyToggled(true);
    setSidebarOpen(!isSidebarOpen);
  };

  const handleOutsideClick = e => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target) && isSidebarOpen && isSmallScreen) {
      setSidebarOpen(false);
      setManuallyToggled(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1024);
      if (window.innerWidth > 1024) {
        setSidebarOpen(true);
      } else {
        if (!manuallyToggled) {
          setTimeout(() => {
            setSidebarOpen(false);
          }, 500);
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    document.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isSidebarOpen, isSmallScreen]);

  return (
    <>
      <div ref={sidebarRef} id="application-sidebar" className={`neo-app-sidebar ${isSmallScreen ? (isSidebarOpen ? 'expanded' : 'collapsed') : 'expanded'}`}>
        <div className="neo-lurchi"></div>
        <div className="neo-sidebar-content">
          <nav>
            <ul>
              {authenticated && <SipMenuItem item={dashboardItem} />}
              <PiletOnlyMenu type="sidebar" />
            </ul>
          </nav>

          <div className="neo-sidebar-bottom">
            <ExtensionSlot name="bottom-sidebar" />
            <VersionInfo />
            <div className="neo-sidebar-imprint">
              <a href={translate('impressum-link')} target="_blank">
                {translate('impressum')}
              </a>
            </div>
          </div>
        </div>
      </div>

      {isSmallScreen && (
        <div className="neo-nav-bar">
          <button type="button" className="neo-sidebar-button" aria-controls="application-sidebar" aria-label="Toggle navigation" onClick={toggleSidebar}>
            <svg width="172" height="172" fill="currentColor" viewBox="0 0 100 100">
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
          <div className="neo-nav-bar-breadcrumbs">
            <Breadcrumbs />
          </div>
        </div>
      )}
    </>
  );
};
