import { KeyIcon } from '@heroicons/react/24/outline';
import { useTranslate } from 'piral-translate';
import React from 'react';

import './login-button.css';

export const LoginButton = ({ keycloak }) => {
  const translate = useTranslate();
  const login = React.useCallback(async () => {
    await keycloak.login();
  }, []);

  return (
    <button className="neo-login-button" onClick={login}>
      <KeyIcon className="neo-login-button-icon" />
      {translate('anmelden')}
    </button>
  );
};
