import React from 'react';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import { useTranslate } from 'piral-translate';
import { storage } from 'piral-core';

import './theme-switch-button.css';

export const ThemeSwitchButton = () => {
  const translate = useTranslate();

  const [darkMode, setDarkMode] = React.useState(() => {
    const isDarkMode = storage.getItem('theme') === 'dark';
    if (isDarkMode) {
      window.document.documentElement.classList.add('dark');
    }
    return isDarkMode;
  });

  const toggleDarkMode = () => {
    const newTheme = darkMode ? 'light' : 'dark';
    storage.setItem('theme', newTheme);
    setDarkMode(!darkMode);

    if (newTheme === 'dark') {
      window.document.documentElement.classList.add('dark');
    } else {
      window.document.documentElement.classList.remove('dark');
    }
  };

  return (
    <div className="neo-theme-switch">
      <button className="neo-theme-switch-button" onClick={toggleDarkMode}>
        {darkMode ? <SunIcon className="neo-theme-switch-icon" /> : <MoonIcon className="neo-theme-switch-icon" />}
        <span className="neo-theme-switch-tooltip" role="tooltip">
          {darkMode ? translate('light-mode') : translate('dark-mode')}
        </span>
      </button>
    </div>
  );
};
