import React from 'react';

import './loading.css';

export const Loading = () => {
  return (
    <div className="neo-loading">
      <div className="neo-loading-container" role="status" aria-label="loading">
        <span className="neo-screen-reader">Loading...</span>
      </div>
    </div>
  );
};
