import React from 'react';
import { ErrorComponentsState } from 'piral-core';
import { useTranslate } from 'piral-translate';
import { Link } from 'react-router-dom';
import { LoginButton } from '../LoginButton/LoginButton';
import { useKeycloak } from '../../keycloak-api/useKeycloak';

import './errors.css';

export const errorComponents: Partial<ErrorComponentsState> = {
  not_found: () => {
    const { keycloak, authenticated } = useKeycloak();
    const translate = useTranslate();

    if (authenticated) {
      const lurchi = require('../../assets/img/lurchi_404.png');
      const pathPos = window.location.pathname.indexOf('/', 1);
      const path = pathPos !== -1 ? window.location.pathname.substring(0, pathPos) : '';
      const link = window.location.origin + path;
      return (
        <>
          <p className="neo-http">{translate('error')} 404</p>
          <p className="neo-error">{translate('seite-existiert-nicht-text', { link })}</p>
          <img className="neo-center" src={lurchi} alt="Lurchi"></img>
          <p>
            <Link to="/" className="neo-underline">
              {translate('zur-startseite')}
            </Link>
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className="neo-http">{translate('error')} 404</p>
          <p>{translate('seite-existiert-nicht-login-text')}</p>
          <p className="neo-login">
            <LoginButton keycloak={keycloak} />
          </p>
        </>
      );
    }
  },
  loading: () => {
    const lurchi = require('../../assets/img/lurchi_404.png');
    const translate = useTranslate();

    return (
      <>
        <p className="neo-http">{translate('error')} 504</p>
        <p className="neo-error">{translate('feed-server-offline')}</p>
        <img className="neo-center" src={lurchi} alt="Lurchi"></img>
        <p>{translate('bitte-versuche-es-spaeter-nochmal')}</p>
      </>
    );
  },
};
