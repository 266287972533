import React from 'react';
import { useGlobalState } from 'piral-core';
import { useTranslate } from 'piral-translate';

import './profile-button.css';

export const ProfileButton = ({ keycloak }) => {
  const translate = useTranslate();
  const languageState = useGlobalState(x => x.language);

  const manageAccount = React.useCallback(() => {
    keycloak.accountManagement();
  }, []);
  const logout = React.useCallback(() => {
    keycloak.logout({ redirectUri: window.location.origin });
  }, []);

  const version = process.env.BUILD_PCKG_VERSION;
  const name = keycloak.idTokenParsed?.['name'];
  const email = keycloak.idTokenParsed?.['email'];
  const initials = !!name
    ? name
        .split(' ')
        .map(n => n[0])
        .join('')
        .toUpperCase()
    : '';

  const toggleDropdown = e => {
    e.currentTarget.nextSibling.classList.toggle('open');
  };

  React.useEffect(() => {
    const handleClickOutside = event => {
      const dropdownMenu = document.querySelector('.neo-profile-dropdown-menu.open');
      if (dropdownMenu && !dropdownMenu.contains(event.target)) {
        dropdownMenu.classList.remove('open');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="neo-profile-button">
      <button type="button" className="neo-profile-dropdown-button" onClick={toggleDropdown}>
        <span className="neo-profile-initials">{initials}</span>
      </button>
      <div className="neo-profile-dropdown-menu">
        <div className="neo-profile-menu-header">
          <p className="version">v{version}</p>
          <p className="details">{translate('angemeldet-als')}:</p>
          <h3 className="name">{name}</h3>
          <p className="details">{email}</p>
        </div>
        <div>
          <button className="neo-profile-menu-button" onClick={manageAccount}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z"
              />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
            {translate('account-verwalten')}
          </button>
          <button className="neo-profile-menu-button" onClick={logout}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
              />
            </svg>
            {translate('abmelden')}
          </button>
        </div>
      </div>
    </div>
  );
};
