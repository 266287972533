import * as React from 'react';
import { ComponentsState, SwitchErrorInfo } from 'piral-core';
import { Notifications } from 'piral-notifications';
import { SnowfallContainer } from '../../snowfall-api';
import { Loading } from '../Loading/Loading';
import { Sidebar } from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import { Toast } from '../Toast/Toast';
import { LoginNotification } from '../LoginNotification/LoginNotification';
import { Header } from '../Header/Header';

import './layout.css';

export const components: Partial<ComponentsState> = {
  ErrorInfo: props => (
    <div className="neo-info-card">
      <SwitchErrorInfo {...props} />
    </div>
  ),
  Layout: ({ children }) => (
    <>
      <SnowfallContainer />
      <Header />
      <Sidebar />

      <div className="neo-app-content">
        <div id="app-content" className="neo-app-content-children">
          <LoginNotification></LoginNotification>
          {children}
        </div>
      </div>
      <Notifications />
    </>
  ),
  LoadingIndicator: Loading,
  NotificationsHost: ({ children }) => <div className="neo-notification-host app-toast-spacing">{children}</div>,
  NotificationsToast: Toast,
  DashboardContainer: ({ children }) => <div className="neo-dashboard">{children}</div>,
  DashboardTile: ({ children }) => <div className="neo-dashboard-tile">{children}</div>,
  BreadcrumbsContainer: ({ children }) => (
    <ol className="neo-breadcrumbs" aria-label="Breadcrumb">
      {children}
    </ol>
  ),
  BreadcrumbItem: ({ children, current, path }) => {
    if (current) {
      return (
        <li className="neo-breadcrumbs-item" aria-current="page">
          {children}
        </li>
      );
    } else {
      return (
        <>
          <Link className="neo-breadcrumbs-item-link" to={path}>
            {children}
          </Link>
          <svg className="neo-breadcrumbs-svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </>
      );
    }
  },
};
