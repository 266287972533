import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import './sip-menu-item.css';

export const SipMenuItem = ({ item }) => {
  if (item.subItems) {
    return <SipMenuAccordeonItem route={item.route} icon={item.icon} title={item.title} subItems={item.subItems} />;
  } else {
    return <SipMenuLinkItem route={item.route} icon={item.icon} title={item.title} badge={item.badge} tooltip={item.tooltip} />;
  }
};

const SipMenuLinkItem = ({ route, icon, title, badge, tooltip }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (route === '/') {
      setIsActive(false);
    } else {
      setIsActive(location.pathname === route || location.pathname.startsWith(route + '/'));
    }
  }, [location.pathname, route]);

  const linkClass = isActive ? 'neo-menu-link active' : 'neo-menu-link';
  const tooltipClass = tooltip ? 'neo-tooltip' : '';

  return (
    <li className="neo-sip-menu-item">
      <div className={`neo-menu-link-wrapper ${tooltipClass}`}>
        <Link id={title} to={route} className={linkClass}>
          <img className="neo-menu-icon" src={icon} alt="icon" />
          <div className="neo-menu-content">
            <p className="neo-menu-title">{title}</p>
            {badge && <span className="neo-menu-badge">{badge}</span>}
          </div>
        </Link>
        {tooltip && <span className="neo-tooltip-text">{tooltip}</span>}
      </div>
    </li>
  );
};

const SipMenuAccordeonItem = ({ route, icon, title, subItems }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const routeId = String(route).replaceAll('/', '').replaceAll('#', '').replaceAll('-', '');

  return (
    <li className="neo-menu-accordion" id={`accordion-for-${routeId}`}>
      <button
        type="button"
        className={`neo-accordion-toggle ${isOpen ? 'active' : ''}`}
        onClick={toggleAccordion}
        aria-expanded={isOpen}
        aria-controls={`neo-accordion-collapse-for-${routeId}`}>
        <img className="neo-menu-icon" src={icon} alt="icon" />
        <p className="neo-menu-title">{title}</p>
        <span className={`neo-accordion-icon ${isOpen ? 'expanded' : ''}`} />
      </button>
      <div id={`neo-accordion-collapse-for-${routeId}`} className={`neo-accordion-content ${isOpen ? 'open' : ''}`}>
        <ul>{subItems && subItems.map((sub, index) => <SipMenuLinkItem key={`${title}-sub-${index}`} {...sub} />)}</ul>
      </div>
    </li>
  );
};
