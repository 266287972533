import React from 'react';
import { Link } from 'react-router-dom';

import './sip-logo.css';

const sipLogo = require('../../assets/svgs/logo-smw.svg');

export const SipLogo = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return (
      <Link to="/">
        <img className="neo-sip-logo" src={sipLogo} alt="Salamander Logo"></img>
      </Link>
    );
  } else {
    return <img className="neo-sip-logo" src={sipLogo} alt="Salamander Logo"></img>;
  }
};
