import * as React from 'react';
import { useAction } from 'piral-core';
import { useTranslate } from 'piral-translate';
import { SnowLevels } from '../../types';

import './snowfall-button.css';

export function isChristmasTime(): boolean {
  const today = new Date();
  // von Nikolaus (06.12.)
  const inDecember = today.getTime() > new Date(today.getFullYear(), 11, 6).getTime();
  // bis inkl. Hl. 3 König (06.01.)
  const inJanuary = today.getTime() < new Date(today.getFullYear(), 0, 7).getTime();
  return inDecember || inJanuary;
}

const snowFlake = require('../../../assets/svgs/snowflake.svg');

export const SnowfallButton: React.FC = () => {
  const setSnowLevel = useAction('setSnowLevel');
  const levels: SnowLevels[] = ['0', '1', '2', '3'];
  const translate = useTranslate();
  const [isDropdownOpen, setDropdownOpen] = React.useState(false);

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  const closeDropdown = () => setDropdownOpen(false);

  if (!isChristmasTime()) {
    return null;
  }

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest('.neo-snowfall-dropdown')) {
        closeDropdown();
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className="neo-snowfall-dropdown">
      <button type="button" onClick={toggleDropdown} className="neo-snowfall-button" aria-expanded={isDropdownOpen}>
        <img alt="snowflake" src={snowFlake} />
      </button>
      {isDropdownOpen && (
        <div className="neo-snowfall-dropdown-content">
          {levels.map(level => (
            <button
              key={level}
              onClick={() => {
                setSnowLevel(level);
                closeDropdown();
              }}
              className="neo-snowfall-dropdown-item">
              <img alt="snowflake" src={snowFlake} />
              {translate('stufe') + ` ${level}`}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
