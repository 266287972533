import { usePiletApi } from 'piral-core';
import { PiralNotificationTypes } from 'piral-notifications';
import React from 'react';

import './test-buttons.css';

export const TestButtons = () => {
  const { showNotification } = usePiletApi();

  const isLocalhost = window.location.hostname === 'localhost';

  const showBrowserNotification = () => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        new Notification('Benachrichtigung', {
          body: 'Dies ist eine Beispielbenachrichtigung.',
        });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            // Wenn die Berechtigung erteilt wird, senden Sie die Benachrichtigung
            new Notification('Benachrichtigung', {
              body: 'Dies ist eine Beispielbenachrichtigung.',
            });
          }
        });
      }
    }
  };

  const showToast = (type: keyof PiralNotificationTypes) => {
    showNotification('Das ist für den Inhalt gedacht, etwas hat nicht funktioniert oder eben doch.', {
      title: 'Neue Toast Benachrichtigung',
      type,
    });
  };

  const toggleDropdown = e => {
    e.currentTarget.nextSibling.classList.toggle('open');
  };

  React.useEffect(() => {
    const handleClickOutside = event => {
      const dropdownMenu = document.querySelector('.neo-dropdown-menu.open');
      if (dropdownMenu && !dropdownMenu.contains(event.target)) {
        dropdownMenu.classList.remove('open');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (isLocalhost) {
    return (
      <div className="neo-test-button">
        <button id="dropdown-toggle" type="button" className="neo-dropdown-button" onClick={toggleDropdown}>
          <p>TEST</p>
        </button>

        <div className="neo-dropdown-menu" aria-labelledby="dropdown-toggle">
          <div className="neo-dropdown-item">
            <button onClick={showBrowserNotification}>Browser-Notification senden</button>
          </div>
          <div className="neo-dropdown-item">
            <button onClick={() => showToast('error')}>Error-Toast senden</button>
          </div>
          <div className="neo-dropdown-item">
            <button onClick={() => showToast('success')}>Success-Toast senden</button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
