import React from 'react';

import { useKeycloak } from '../../keycloak-api/useKeycloak';
import { Breadcrumbs } from 'piral-breadcrumbs';
import { ExtensionSlot } from 'piral-core';
import { SnowfallButton } from '../../snowfall-api';
import { LoginButton } from '../LoginButton/LoginButton';
import { ProfileButton } from '../ProfileButton/ProfileButton';
import { SipLogo } from '../SipLogo/SipLogo';
import { TestButtons } from '../TestButtons/TestButtons';
import { ThemeSwitchButton } from '../ThemeSwitchButton/ThemeSwitchButton';

import './header.css';

export const Header = () => {
  const { keycloak } = useKeycloak();
  return (
    <header className="neo-header">
      <nav className="neo-header-nav" aria-label="Global">
        <div className="neo-header-logo" id="logo-in-header">
          <SipLogo isAuthenticated={keycloak.authenticated} />
        </div>

        <div className="neo-header-breadcrumbs">
          <Breadcrumbs />
        </div>

        <div className="neo-header-button-row">
          {!keycloak.authenticated && location.pathname.includes('salamander-info') && (
            <>
              <LoginButton keycloak={keycloak} />
            </>
          )}
          {keycloak.authenticated && (
            <>
              <TestButtons />
              <ThemeSwitchButton />
              <SnowfallButton />
              <ExtensionSlot name="notifications" />
              <ProfileButton keycloak={keycloak} />
            </>
          )}
        </div>
      </nav>
    </header>
  );
};
